<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название"
              >
                <b-form-input
                  v-model="subscription.name"
                  placeholder="Название"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Вид абонемента"
              >
                <b-form-input
                  v-model="subscription.type"
                  placeholder="Вид абонемента"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Вид спорта"
              >
              <v-select
                v-model="sportOption"
                :close-on-select="true"
                :options="sportOptions"
                :reduce="sportOption => sportOption"
                label="name"
                input-id="name"
                :value="sportOption"
                class="select"
                @search="(search, loading) => getKindsOfSport(search, loading)"
              />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card">
      <div v-if="img" class="card-body">
        <img :src="img" alt="avatar">
      </div>
      <div class="card-body">
        <div class="upload-wrap">
          <file-uploader
            v-model="img"
            :default="img"
            @updatePhotos="getPhotos"
            title="Картинка"
          />
        </div>
      </div>
    </div>

      <div class="card">
        <div class="card-header" />
        <div class="card-body">
          <h4 class="card-title">
            Описание
          </h4>
          <div class="my-1">
            Описание
          </div>
          <quill-editor
            v-model="subscription.description"
          />
        </div>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Цена"
              >
                <b-form-input
                  v-model.number="subscription.price"
                  placeholder="Цена"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Скидка"
              >
                <b-form-input
                  v-model.number="subscription.discount"
                  placeholder="Скидка, %"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import crud_module from '../../../libs/crud_module'
import FileUploader from '@/global-components/FileUploader'

const subscription_module = crud_module({
  entity_name: 'subscription',
})

const kind_of_sport_module = crud_module({
  entity_name: 'kind-of-sport',
})

export default {

  name: 'SchedulePage',
  components: {
    quillEditor,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    FileUploader
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      months: [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь'
      ],
      isloading: false,
      downloadDisabled: false,
      subscription: {},
      sportOptions: [],
      defaultSportOptions: [],
      sportOption: null,
      debounce: null,
      img: null,
    }
  },
  async created() {
    await kind_of_sport_module.fetchItems()
      .then(result => {
        this.sportOptions = result.data.rows
        this.defaultSportOptions = result.data.rows
      })
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await subscription_module.fetchItem(this.$route.params.id, {
        query: {
          admin: '1',
        },
      }).then(result => {
        this.subscription = result.data
        this.sportOption = result.data.kindOfSport
      })
    } catch (e) {
      console.error(e)
    }
  },
  watch: {
    sportOption() {
      this.subscription.kindOfSportId = this.sportOption?.id
    }
  },
  methods: {
    getPhotos(data) {
      this.img = data.fullPath
    },
    saveItem() {
      if (this.$route.params.id === 'new') {
        this.create()
      } else {
        this.update()
      }
    },
    async update() {
      try {
        await subscription_module.updateItem(this.subscription.id, this.subscription)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Абонемент сохранен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'subscriptions' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async create() {
      try {
        await subscription_module.createItem(this.subscription)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Абонемент добавлен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'subscriptions' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length > 0) {
        this.debounce = setTimeout(fn, 600)
      } else {
        this.sportOptions = this.defaultSportOptions
      }
    },
    getKindsOfSport(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await kind_of_sport_module.fetchItems({ filterText: search })
            .then(result => {
              this.sportOptions = result.data.rows
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
